import { Slider } from 'antd';
import React from 'react';

function LoadedBar({
  buffered,
  duration
}: {
  buffered: number;
  duration: number;
}) {
  return (
    <Slider className="loaded" value={(buffered / duration) * 100} step={0.1} />
  );
}

export default LoadedBar;
