import {
  AssetVersionCommentGroupMentionDto,
  AssetVersionCommentMentionDto
} from '@api/Api';
import UserAvatar from '@components/UserAvatar';
import { useTypedSelector } from '@hooks';
import { selectComment, setCanvasMode } from '@redux/actions/mediaViewerAction';
import { Tooltip } from 'antd';
import classNames from 'classnames';
import parse from 'html-react-parser';
import React from 'react';
import { useDispatch } from 'react-redux';

function Mark({
  id,
  user,
  color,
  comment,
  isPaused,
  isExpand,
  isRange,
  mentions,
  groupMentions
}: {
  id: string;
  color: string;
  user: any;
  comment: string;
  isPaused: boolean;
  isExpand: boolean;
  isRange: boolean;
  mentions: AssetVersionCommentMentionDto[];
  groupMentions: AssetVersionCommentGroupMentionDto[];
}) {
  const dispatch = useDispatch();
  const selectedCommentId = useTypedSelector(
    ({ mediaViewer }) => mediaViewer.selectedCommentId
  );
  const canvasMode = useTypedSelector(
    ({ mediaViewer }) => mediaViewer.canvasMode
  );

  if (isExpand || canvasMode === 'edit') return null;

  const prepareHtml = () => {
    let html = comment;
    mentions.forEach((el) => {
      html = html.replaceAll(
        `{{${el.id}}}`,
        `<span class="b-suggestion-user">@${el.firstName} ${el.lastName}</span>`
      );
    });
    groupMentions.forEach((el) => {
      html = html.replaceAll(
        `{{${el.id}}}`,
        `<span class="b-suggestion-user">@${el.name}</span>`
      );
    });
    html = html.replaceAll(
      /{{(?:[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})}}/g,
      `<span class="b-suggestion-user">@Deleted User</span>`
    );
    return parse(html);
  };
  return (
    <span
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
      className={classNames(
        selectedCommentId === id ? (isRange ? 'for_range' : 'for_pin') : '',
        'mark'
      )}
    >
      <Tooltip
        placement="top"
        overlayClassName="selected_mark_overlay"
        getPopupContainer={() =>
          document.querySelector('.video-js') as HTMLElement
        }
        title={
          <div className="hint" style={{ outlineColor: color }}>
            <UserAvatar
              isActive={true}
              size={24}
              src={user?.picture.url || ''}
              userEmail={user?.email || ''}
              userName={user?.name || ''}
            />
            <div>{prepareHtml()}</div>
          </div>
        }
        trigger={['click']}
        open={!!(selectedCommentId === id) && isPaused}
        onOpenChange={(visible) => {
          if (visible) {
            dispatch(setCanvasMode('view'));
            dispatch(selectComment(id));
          }
        }}
      >
        <svg
          width="14"
          height="13"
          style={
            selectedCommentId && selectedCommentId !== id
              ? { opacity: 0.3 }
              : selectedCommentId === id
              ? { transform: 'scale(1.5)' }
              : {}
          }
          viewBox="0 0 14 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3.61803 11.999L10.382 11.999C11.8687 11.999 12.8357 10.4344 12.1708 9.10459L8.78885 2.34066C8.05181 0.866567 5.94819 0.866568 5.21115 2.34066L6.10557 2.78788L5.21115 2.34066L1.82918 9.1046C1.16428 10.4344 2.13127 11.999 3.61803 11.999Z"
            fill={color}
            stroke="#292B2E"
            strokeWidth="2"
          />
        </svg>
      </Tooltip>
    </span>
  );
}

export default Mark;
