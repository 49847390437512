/* eslint-disable react/jsx-no-useless-fragment */
import { hashedColor } from '@helpers/hashedColor';
import { useSelectedMVComment, useTypedSelector } from '@hooks';
import { setPlayerTime } from '@redux/actions/mediaViewerAction';
import { Slider } from 'antd';
import classNames from 'classnames';
import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';

function MarksBar({
  duration,
  timeRange,
  setTimeRange,
  onSliderChange
}: {
  duration: number;
  timeRange: number[];
  setTimeRange: (a: number[]) => void;
  onSliderChange: (a: number) => void;
}) {
  const containerRef = useRef(null);
  const dispatch = useDispatch();
  const commentDetails = useSelectedMVComment();
  const canvasMode = useTypedSelector(
    ({ mediaViewer }) => mediaViewer.canvasMode
  );
  const isTimecodeChecked = useTypedSelector(
    ({ mediaViewer }) => mediaViewer.isTimecodeChecked
  );
  const selectedCommentId = useTypedSelector(
    ({ mediaViewer }) => mediaViewer.selectedCommentId
  );

  if (!isTimecodeChecked && canvasMode === 'edit') return null;
  return (
    <div ref={containerRef}>
      {selectedCommentId && (
        <Slider
          range={{ draggableTrack: true }}
          step={0.1}
          value={[
            (timeRange[0] / duration) * 100 -
              (canvasMode === 'view' &&
              selectedCommentId !== 'new' &&
              selectedCommentId !== 'reply'
                ? 0
                : 0),
            (timeRange[1] / duration) * 100 +
              (canvasMode === 'view' &&
              selectedCommentId !== 'new' &&
              selectedCommentId !== 'reply'
                ? 0
                : 0)
          ]}
          tooltip={{
            placement: 'bottom',
            getPopupContainer: () => containerRef.current as any,
            formatter: () => <>Select a timecode range</>
          }}
          trackStyle={[
            {
              opacity: 0.5,
              background: hashedColor(
                commentDetails?.user?.id || '',
                'mediaViewerComment'
              )
            }
          ]}
          onChange={(v: any) => {
            if (
              canvasMode === 'view' &&
              selectedCommentId !== 'new' &&
              selectedCommentId !== 'reply'
            )
              return;

            const start = duration * (v[0] / 100);
            const end = duration * (v[1] / 100);
            setTimeRange([start, end]);
            onSliderChange(duration * (v[0] / 100));
          }}
          onAfterChange={(v: any) => {
            if (
              canvasMode === 'view' &&
              selectedCommentId !== 'new' &&
              selectedCommentId !== 'reply'
            )
              return;
            const start = duration * (v[0] / 100);
            const end = duration * (v[1] / 100);

            dispatch(setPlayerTime([start, start === end ? null : end]));
          }}
          className={classNames(
            canvasMode === 'view' &&
              selectedCommentId !== 'new' &&
              selectedCommentId !== 'reply' &&
              timeRange[0] !== timeRange[1] &&
              'mark_timeline_borders',
            (canvasMode === 'edit' ||
              selectedCommentId === 'new' ||
              selectedCommentId === 'reply') &&
              'mark_timeline_edit_mode',
            'mark_timeline'
          )}
        />
      )}
    </div>
  );
}

export default MarksBar;
