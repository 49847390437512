import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ControlBarSlider from '@pages/MediaViewer/AssetViewer/VideoCard/components/Slider';
import { ReactComponent as Play } from '@assets/icons/PlayerControlBar/play.svg';
import { ReactComponent as Pause } from '@assets/icons/PlayerControlBar/pause.svg';
import { ReactComponent as FastRewind } from '@assets/icons/PlayerControlBar/fast-rewind.svg';
import { ReactComponent as FastForward } from '@assets/icons/PlayerControlBar/fast-forward.svg';
import { ReactComponent as Volume } from '@assets/icons/PlayerControlBar/volume.svg';
import { ReactComponent as PlayCircles } from '@assets/icons/PlayerControlBar/play-with-circles.svg';
import { ReactComponent as PauseCircles } from '@assets/icons/PlayerControlBar/pause-with-circles.svg';

import { ReactComponent as Repeat } from '@assets/icons/PlayerControlBar/repeat.svg';
import { ReactComponent as Expand } from '@assets/icons/PlayerControlBar/expand.svg';
import { ReactComponent as Collapse } from '@assets/icons/PlayerControlBar/collapse.svg';
import { ReactComponent as More } from '@assets/icons/MediaViewer/more.svg';
import { Dropdown, Menu, Popover, Slider, Tooltip } from 'antd';
import { formatDuration } from '@helpers/formatDuration';
import { checkCanPlaySource } from '@helpers/checkCanPlaySource';
import classNames from 'classnames';
import { useSelectedMVComment, useTypedSelector } from '@hooks';

import './ControlBar.less';
import { useDispatch } from 'react-redux';
import {
  selectComment,
  setIsSourceSelected,
  setPlayerTime,
  setTimeFormat
} from '@redux/actions/mediaViewerAction';
import AssetInfo from '@pages/MediaViewer/AssetViewer/AssetInfo';
import LottieComponent from '@components/Lottie';
import { LgWidth, MdWidth } from '@components/Responsive';
import { useResponsive } from '@hooks/useResponsive';
import ControlBarTimer from '@pages/MediaViewer/AssetViewer/VideoCard/components/ControlBarTimer/ControlBarTimer';
import {
  AssetProxyInfoDto,
  AssetVersionItemDto,
  AudioVideoMetadataDto
} from '@api/Api';
import { VideoJsPlayer } from 'video.js';
import useKeys from '@pages/MediaViewer/AssetViewer/VideoCard/components/ControlBar/hooks/useKeys';

let timeMobileController: ReturnType<typeof setTimeout>;
let intervalRewind: ReturnType<typeof requestAnimationFrame>;
const speedMenuItems = [
  {
    key: '0.5',
    label: '0.5x'
  },
  {
    key: '0.75',
    label: '0.75x'
  },
  {
    key: '1',
    label: '1x'
  },
  {
    key: '1.25',
    label: '1.25x'
  },
  {
    key: '1.5',
    label: '1.5x'
  },
  {
    key: '2',
    label: '2x'
  }
];

const timeFormatMenuItems = [
  {
    key: 'standard',
    label: 'Standard'
  },
  {
    key: 'frames',
    label: 'Frames'
  }
];

function ControlBar({
  player,
  asset
}: {
  player: VideoJsPlayer;
  asset: AssetVersionItemDto;
}) {
  const dispatch = useDispatch();
  const { isMdWidth } = useResponsive();
  const isToolsExpanded = useTypedSelector(
    ({ mediaViewer }) => mediaViewer.isToolsExpanded
  );
  const playerTime = useTypedSelector(
    ({ mediaViewer }) => mediaViewer.playerTime
  );
  const selectedVersion = useTypedSelector(
    ({ mediaViewer }) => mediaViewer.selectedVersion
  );
  const canvasMode = useTypedSelector(
    ({ mediaViewer }) => mediaViewer.canvasMode
  );
  const selectedCommentId = useTypedSelector(
    ({ mediaViewer }) => mediaViewer.selectedCommentId
  );
  const timeFormat = useTypedSelector(
    ({ mediaViewer }) => mediaViewer.timeFormat
  );

  const commentDetails = useSelectedMVComment();
  const [isHideMobileController, setIsHideMobileController] =
    useState<boolean>(false);

  const [isPlayReverse, setIsPlayReverse] = useState<boolean>(false);
  const [isPaused, setIsPaused] = useState<boolean>(true);
  const [isExpand, setIsExpand] = useState<boolean>(false);
  const [currentTime, setCurrentTime] = useState<number>(0);
  const [duration, setDuration] = useState<number>(0);
  const [volume, setVolume] = useState<number>(1);
  const [isLooped, setIsLooped] = useState<boolean>(false);
  const [playbackSpeed, setPlaybackSpeed] = useState<number>(1);

  const onFastForward = (value: number) => {
    const currentTime = player.currentTime();
    player.currentTime(currentTime + value);
  };

  const { playInReverse, playPause } = useKeys({
    duration,
    onFastForward,
    player,
    isPaused,
    setPlaybackSpeed,
    setIsPaused,
    isLooped
  });

  const onPlayPause = (value: boolean) => {
    if (playInReverse) {
      playPause();
      return;
    }

    if (value) {
      player.play();

      setIsHideMobileController(true);
      if (!playerTime[1]) dispatch(selectComment(null));
    } else {
      player.pause();
    }
  };
  const onSliderChange = (value: number) => {
    player.currentTime(value);
  };
  const onVolumeChange = (volume: number) => {
    player.volume(volume);
  };
  const onLoopChange = (value: boolean) => {
    player.loop(value);
    setIsLooped((prev) => !prev);
  };
  const onPlayBackRateChange = (value: number) => {
    player.playbackRate(value);
    setPlaybackSpeed(value);
  };
  const onFullscreenChange = (value: boolean) => {
    if (value) player.requestFullscreen();
    else player.exitFullscreen();
  };

  const qualityItems = useMemo(() => {
    if (!selectedVersion) return [];
    const metadata = selectedVersion?.metadata as AudioVideoMetadataDto;
    const proxies =
      Object.entries(metadata.videoProxies)
        .map(([key, info]: [string, AssetProxyInfoDto]) => ({
          quality: parseInt(key, 10),
          src: info.url,
          status: info.status
        }))
        .filter((x) => x.status !== 'skipped')
        .sort((a, b) => b.quality - a.quality)
        .map((x) => ({
          key: x.src ?? `${x.quality}`,
          label: `${x.quality}p`,
          disabled: !x.src,
          isHD: x.quality >= 720,
          isOriginal: false
        })) || [];
    const result = [...proxies];
    if (checkCanPlaySource(selectedVersion)) {
      result.push({
        key: selectedVersion.sourceUrl,
        label: 'Original',
        disabled: false,
        isHD: proxies.some((x) => x.isHD),
        isOriginal: true
      });
    }
    return result;
  }, [selectedVersion]);

  const currentQuality = qualityItems.find((x) => x.key === player.src());

  const onChangeQuality = (src: string) => {
    if (!selectedVersion) return;
    const metadata = selectedVersion.metadata as AudioVideoMetadataDto;
    const currentTime = player.currentTime();
    const proxy = Object.entries(metadata.videoProxies)
      .map(([key, value]) => ({ key, value }))
      .find((el: any) => el.value.url === src);
    player.src({
      src,
      type: proxy ? 'video/mp4' : selectedVersion.mimeType
    });
    player.currentTime(currentTime);
    player.play();
  };
  useEffect(() => {
    dispatch(setIsSourceSelected(!!currentQuality?.isOriginal));
  }, [currentQuality, dispatch]);

  useEffect(() => {
    const onPause = () => {
      setIsPaused(true);
      setIsHideMobileController(false);
      clearTimeout(timeMobileController);
    };
    const onPlay = () => {
      setIsPaused(false);
    };
    const onTimeUpdate = () => {
      const currentTime = player.currentTime();
      setCurrentTime(currentTime);
    };
    const onVolumeChange = () => {
      const volume = player.volume();
      setVolume(volume);
    };
    const onEnded = () => {
      setIsPaused(true);
      setIsHideMobileController(false);
      clearTimeout(timeMobileController);
    };
    const onLoadedMetadata = () => {
      setDuration(player.duration());
    };
    const onFullscreenChange = () => {
      setIsExpand(player.isFullscreen());
      dispatch(selectComment(null));
    };
    player.on('pause', onPause);
    player.on('play', onPlay);
    player.on('timeupdate', onTimeUpdate);
    player.on('volumechange', onVolumeChange);
    player.on('ended', onEnded);
    player.on('loadedmetadata', onLoadedMetadata);
    player.on('fullscreenchange', onFullscreenChange);
    return () => {
      player.off('pause', onPause);
      player.off('play', onPlay);
      player.off('timeupdate', onTimeUpdate);
      player.off('volumechange', onVolumeChange);
      player.off('ended', onEnded);
      player.off('loadedmetadata', onLoadedMetadata);
      player.off('fullscreenchange', onFullscreenChange);
    };
  }, [player, dispatch]);

  useEffect(() => {
    if (!player) return;
    if (!commentDetails?.timeCode?.toSeconds && !playerTime[1]) return;
    const onEscKeyup = (e: any) => {
      if (e.key === 'Escape') {
        if (
          (canvasMode === 'edit' ||
            selectedCommentId === 'new' ||
            selectedCommentId === 'reply') &&
          playerTime[1] &&
          playerTime[1] >= 0
        ) {
          dispatch(setPlayerTime([playerTime[0], null]));
        }
      }
    };

    document.addEventListener('keyup', onEscKeyup);
    return () => document.removeEventListener('keyup', onEscKeyup);
  }, [
    player,
    commentDetails,
    selectedCommentId,
    playerTime,
    canvasMode,
    dispatch
  ]);

  useEffect(() => {
    if (!player) return;
    if (
      playerTime[1] &&
      playerTime[1] >= 0 &&
      (canvasMode === 'edit' ||
        selectedCommentId === 'new' ||
        selectedCommentId === 'reply')
    ) {
      if (currentTime < (playerTime[0] as number)) {
        if (!isLooped) player.pause();
        player.currentTime(playerTime[1] ?? 0);
      }

      if (currentTime > playerTime[1]) {
        if (!isLooped) player.pause();
        player.currentTime(playerTime[0] ?? 0);
      }
      return;
    }
    if (!commentDetails?.timeCode?.toSeconds) return;

    if (currentTime < commentDetails.timeCode.fromSeconds)
      player.currentTime(commentDetails.timeCode.fromSeconds);
    if (currentTime > commentDetails.timeCode.toSeconds) {
      if (!isLooped) player.pause();
      player.currentTime(commentDetails.timeCode.fromSeconds);
    }
  }, [player, commentDetails, currentTime, isLooped, canvasMode, playerTime]);

  useEffect(() => {
    if (!player || !commentDetails?.timeCode) return;
    player.currentTime(commentDetails.timeCode.fromSeconds);
    player.pause();
  }, [player, commentDetails]);

  const renderCommentTime = useCallback(
    (time: number) => {
      const metadata = selectedVersion?.metadata as AudioVideoMetadataDto;
      const sourceFrameRate = metadata.info.avInfo?.video?.frameRate ?? 30;
      const proxyFrameRate = Math.min(sourceFrameRate, 30);
      const frameRate = currentQuality?.isOriginal
        ? sourceFrameRate
        : proxyFrameRate;

      if (timeFormat === 'frames') {
        return Math.floor(time * frameRate);
      }

      return formatDuration(time);
    },
    [currentQuality, selectedVersion?.metadata, timeFormat]
  );

  const renderAssetTime = useMemo(() => {
    return (
      <>
        <div className="current">{renderCommentTime(currentTime)}</div>
        <div className="divider">/</div>
        <div className="total">{renderCommentTime(duration)}</div>
      </>
    );
  }, [renderCommentTime, currentTime, duration]);

  const onShowControlController = () => {
    clearTimeout(timeMobileController);
    setIsHideMobileController(false);
    timeMobileController = setTimeout(() => {
      setIsHideMobileController(true);
    }, 3000);
  };

  const getFasForwardValue = useCallback(() => {
    const metadata = selectedVersion?.metadata as AudioVideoMetadataDto;
    const isSource = !!currentQuality?.isOriginal;
    const sourceFrameRate = metadata.info.avInfo?.video?.frameRate ?? 30;
    const proxyFrameRate = Math.min(sourceFrameRate, 30);
    const frameRate = isSource ? sourceFrameRate : proxyFrameRate;

    return timeFormat === 'frames' ? 1 / frameRate : 5;
  }, [currentQuality, selectedVersion?.metadata, timeFormat]);

  const controllerMobileMenuItems = [
    {
      label: (
        <>
          Quality
          <span className="selected-value">
            {currentQuality?.label}
            {!!currentQuality?.isHD && <span className="resolution">HD</span>}
          </span>
        </>
      ),
      popupClassName: 'media_viewer_submenu media_viewer_mobile-overlay',
      onClick: ({ key }: { key: string }) => onChangeQuality(key),
      children: qualityItems.map((x) => ({
        key: x.key,
        label: x.label,
        disabled: x.disabled
      })),
      key: 'quality'
    },
    {
      label: (
        <>
          Loop video
          <span className="selected-value">{isLooped ? 'On' : 'Off'}</span>
        </>
      ),
      onClick: () => onLoopChange(!isLooped),
      key: 'loop-video'
    },
    {
      label: (
        <>
          Playback speed
          <span className="selected-value">{playbackSpeed}x</span>
        </>
      ),
      key: 'submenu-speed',
      popupClassName: 'media_viewer_submenu media_viewer_mobile-overlay',
      onClick: ({ key }: { key: string }) =>
        key !== 'cancel-speed' && onPlayBackRateChange(+key),
      children: [
        ...speedMenuItems,
        {
          key: 'cancel-speed',
          label: 'Cancel'
        }
      ]
    },
    {
      label: (
        <>
          Time format
          <span
            className="selected-value"
            style={{ textTransform: 'capitalize' }}
          >
            {timeFormat}
          </span>
        </>
      ),
      popupClassName: 'media_viewer_submenu media_viewer_mobile-overlay',
      onClick: ({ key }: { key: string }) =>
        key !== 'cancel-time' && dispatch(setTimeFormat(key as any)),
      key: 'time-format',
      children: [
        ...timeFormatMenuItems,
        {
          key: 'cancel-time',
          label: 'Cancel'
        }
      ]
    },
    {
      key: 'cancel',
      label: 'Cancel'
    }
  ];

  return (
    <>
      {isMdWidth && isPaused && !isExpand && (
        <Dropdown
          menu={{
            className: 'video_controller_mobile',
            selectable: false,
            items: controllerMobileMenuItems
          }}
          prefixCls="media_viewer_menu"
          overlayClassName="media_viewer_comment_item-overlay media_viewer_mobile-overlay"
          placement="bottomRight"
          trigger={['click']}
        >
          <More className="video_controller_mobile-icon" />
        </Dropdown>
      )}
      <div
        className={classNames(
          (isExpand || isToolsExpanded) && 'background_wrapper_expanded',
          'background_wrapper'
        )}
        onClick={() => {
          onPlayPause(isPaused);
        }}
      >
        {asset.metadata.type === 'audio' && (
          <LottieComponent
            view="audio"
            className="icon-audio"
            isStopped={isPaused}
            autoPlay={false}
          />
        )}

        {isExpand && isPaused && <AssetInfo />}
      </div>
      <div className={classNames(isExpand && 'video_control_bar_wrapper')}>
        <div
          className={classNames(
            !isPaused && isExpand && 'video_control_bar_expanded',
            'video_control_bar'
          )}
        >
          <div className="video_control_bar_rail">
            <ControlBarSlider
              asset={asset}
              currentTime={currentTime}
              duration={duration}
              onSliderChange={onSliderChange}
              isPaused={isPaused}
              isExpand={isExpand}
              buffered={player.bufferedEnd()}
            />
          </div>
          {isMdWidth && !isToolsExpanded && !selectedCommentId && (
            <>
              {isHideMobileController ? (
                <div
                  className="toggle_controller_mobile"
                  onClick={onShowControlController}
                />
              ) : (
                <div className="video_control_bar_container__controller_mobile">
                  {isPaused ? (
                    <PlayCircles onClick={() => onPlayPause(true)} />
                  ) : (
                    <>
                      <FastRewind
                        onClick={() => {
                          const value = getFasForwardValue();
                          onFastForward(-value);
                        }}
                      />
                      <PauseCircles onClick={() => onPlayPause(false)} />
                      <FastForward
                        onClick={() => {
                          const value = getFasForwardValue();
                          onFastForward(+value);
                        }}
                      />
                    </>
                  )}
                </div>
              )}
            </>
          )}
          <MdWidth>
            {!isToolsExpanded && !selectedCommentId && (
              <>
                {isHideMobileController ? (
                  <div
                    className="toggle_controller_mobile"
                    onClick={onShowControlController}
                  />
                ) : (
                  <div className="video_control_bar_container__controller_mobile">
                    {isPaused ? (
                      <PlayCircles onClick={() => onPlayPause(true)} />
                    ) : (
                      <>
                        <FastRewind onClick={() => onFastForward(-3)} />
                        <PauseCircles onClick={() => onPlayPause(false)} />
                        <FastForward onClick={() => onFastForward(+3)} />
                      </>
                    )}
                  </div>
                )}
              </>
            )}
            <div className="video_control_bar_container__bottom-panel">
              <div className="video_control_bar_container__time">
                {renderAssetTime}
              </div>
              {isExpand ? (
                <Collapse
                  className="icon-expand"
                  onClick={() => onFullscreenChange(false)}
                />
              ) : (
                <Expand
                  className="icon-expand"
                  onClick={() => onFullscreenChange(true)}
                />
              )}
            </div>
          </MdWidth>
          <LgWidth>
            {/* {!isToolsExpanded &&
              !selectedCommentId &&
              (player.src() && isPaused && !isStartVideoFirst ? (
                <div className="video_control_bar_container__controller_mobile">
                  <PlayCircles onClick={() => onPlayPause(true)} />1
                </div>
              ) : (
                <div
                  className="toggle_controller_mobile"
                  onClick={() => onPlayPause(isPaused)}
                />
              ))} */}
            <div className="video_control_bar_container">
              <div className="video_control_bar_container__controller">
                <Tooltip
                  title={isPaused ? 'Play' : 'Pause'}
                  overlayClassName="toggle_favorite_overlay"
                  placement="bottom"
                >
                  {isPaused ? (
                    <Play onClick={() => onPlayPause(true)} />
                  ) : (
                    <Pause onClick={() => onPlayPause(false)} />
                  )}
                </Tooltip>
                <Tooltip
                  title="-5 seconds"
                  overlayClassName="toggle_favorite_overlay"
                  placement="bottom"
                >
                  <FastRewind
                    onClick={() => {
                      const value = getFasForwardValue();
                      onFastForward(-value);
                    }}
                  />
                </Tooltip>
                <Tooltip
                  title="+5 seconds"
                  overlayClassName="toggle_favorite_overlay"
                  placement="bottom"
                >
                  <FastForward
                    onClick={() => {
                      const value = getFasForwardValue();
                      onFastForward(+value);
                    }}
                  />
                </Tooltip>
                <Tooltip
                  title={volume ? 'Mute' : 'Unmute'}
                  overlayClassName="toggle_favorite_overlay"
                  placement="bottom"
                >
                  <span>
                    <Popover
                      getPopupContainer={() =>
                        document.querySelector('.video-js') as HTMLElement
                      }
                      placement="right"
                      trigger={['hover']}
                      content={
                        <Slider
                          value={volume * 100}
                          tooltipVisible={false}
                          onChange={(v) => {
                            onVolumeChange(v / 100);
                          }}
                        />
                      }
                      overlayClassName="volume_controller_overlay"
                    >
                      <Volume
                        className={classNames(
                          volume <= 0.5 && volume > 0
                            ? 'half'
                            : volume === 0
                            ? 'muted'
                            : volume > 0.5
                            ? 'hight'
                            : ''
                        )}
                        onClick={() => {
                          if (!volume) onVolumeChange(0.5);
                          else onVolumeChange(0);
                        }}
                      />
                    </Popover>
                  </span>
                </Tooltip>
                <div className="volume" />
              </div>
              <ControlBarTimer
                currentTime={currentTime}
                duration={duration}
                isSource={!!currentQuality?.isOriginal}
              />

              <div className="video_control_bar_container__options">
                <Popover
                  placement="topRight"
                  trigger={['click']}
                  getPopupContainer={() =>
                    document.querySelector('.video-js') as HTMLElement
                  }
                  overlayClassName="control_bar_select_overlay"
                  overlayStyle={{ width: 140 }}
                  content={
                    <Menu
                      prefixCls="media_viewer_menu-menu"
                      selectedKeys={[playbackSpeed.toString()]}
                      onClick={({ key }) => onPlayBackRateChange(+key)}
                      items={speedMenuItems}
                    />
                  }
                >
                  <Tooltip
                    title="Speed"
                    overlayClassName="toggle_favorite_overlay"
                    placement="bottom"
                  >
                    <div className="speed">{playbackSpeed}x</div>
                  </Tooltip>
                </Popover>
                <Tooltip
                  title="Repeat"
                  overlayClassName="toggle_favorite_overlay"
                  placement="bottom"
                >
                  <Repeat
                    className={classNames(!isLooped && 'loop_disabled')}
                    onClick={() => onLoopChange(!isLooped)}
                  />
                </Tooltip>

                {asset.metadata.type !== 'audio' && (
                  <>
                    <Popover
                      placement="topRight"
                      trigger={['click']}
                      getPopupContainer={() =>
                        document.querySelector('.video-js') as HTMLElement
                      }
                      overlayClassName="control_bar_select_overlay"
                      overlayStyle={{ width: 200 }}
                      content={
                        <Menu
                          prefixCls="media_viewer_menu-menu"
                          selectedKeys={
                            currentQuality ? [currentQuality.key] : []
                          }
                          onClick={({ key }) => {
                            onChangeQuality(key);
                          }}
                          items={qualityItems.map((x) => ({
                            key: x.key,
                            label: x.label,
                            disabled: x.disabled
                          }))}
                        />
                      }
                    >
                      <Tooltip
                        title="Quality"
                        overlayClassName="toggle_favorite_overlay"
                        placement="bottom"
                      >
                        <div className="quality">
                          {currentQuality?.label}
                          {!!currentQuality?.isHD && (
                            <span className="resolution">HD</span>
                          )}
                        </div>
                      </Tooltip>
                    </Popover>
                    <Tooltip
                      title="Full screen"
                      overlayClassName="toggle_favorite_overlay"
                      placement="bottom"
                    >
                      {isExpand ? (
                        <Collapse onClick={() => onFullscreenChange(false)} />
                      ) : (
                        <Expand onClick={() => onFullscreenChange(true)} />
                      )}
                    </Tooltip>
                  </>
                )}
              </div>
            </div>
          </LgWidth>
        </div>
      </div>
    </>
  );
}

export default ControlBar;
