import { AssetVersionItemDto } from '@api/Api';
import { formatDuration } from '@helpers/formatDuration';
import { useSelectedMVComment, useTypedSelector } from '@hooks';
import { Slider } from 'antd';
import { useDispatch } from 'react-redux';
import React, { useEffect, useRef, useState } from 'react';
import { hashedColor } from '@helpers/hashedColor';
import { selectComment, setPlayerTime } from '@redux/actions/mediaViewerAction';
import LoadedBar from '@pages/MediaViewer/AssetViewer/VideoCard/components/Slider/LoadedBar';
import MarksBar from '@pages/MediaViewer/AssetViewer/VideoCard/components/Slider/MarksBar';
import Mark from '@pages/MediaViewer/AssetViewer/VideoCard/components/Slider/Mark';

function ControlBarSlider({
  asset,
  currentTime,
  duration,
  onSliderChange,
  isPaused,
  isExpand,
  buffered
}: {
  asset: AssetVersionItemDto;
  currentTime: number;
  duration: number;
  onSliderChange: (a: number) => void;
  isPaused: boolean;
  isExpand: boolean;
  buffered: number;
}) {
  const dispatch = useDispatch();
  const commentsList = useTypedSelector(
    ({ mediaViewer }) => mediaViewer.commentsList
  );
  const selectedCommentId = useTypedSelector(
    ({ mediaViewer }) => mediaViewer.selectedCommentId
  );
  const canvasMode = useTypedSelector(
    ({ mediaViewer }) => mediaViewer.canvasMode
  );

  const playerTime = useTypedSelector(
    ({ mediaViewer }) => mediaViewer.playerTime
  );
  const isCommentFieldFocused = useTypedSelector(
    ({ mediaViewer }) => mediaViewer.isCommentFieldFocused
  );
  const isTimecodeChecked = useTypedSelector(
    ({ mediaViewer }) => mediaViewer.isTimecodeChecked
  );

  const assetType = asset.metadata.type;

  const commentDetails = useSelectedMVComment();
  const scrubRef = useRef<any>();
  const scrubContainerRef = useRef<any>();
  const scrubLineRef = useRef<any>();
  const scrubTimeRef = useRef<any>();

  const [scrubsVisible, setScrubsVisible] = useState<boolean>(true);
  const [scrubsUrl, setScrubsUrl] = useState<string>('');
  const [frameWidth, setFrameWidth] = useState<number>(0);
  const [frameHeight, setFrameHeight] = useState<number>(0);
  const [framesCount, setFramesCount] = useState<number>(0);
  const [timeRange, setTimeRange] = useState<number[]>([]);

  useEffect(() => {
    if (isCommentFieldFocused) return;

    const onKeyPress = (e: any) => {
      const modalElement = document.querySelector('.ant-modal-root');
      const fieldElement = document.querySelector(
        '.media_viewer_filter-search-input'
      );

      if (modalElement || fieldElement) return;
      if (e.code !== 'KeyI' && e.code !== 'KeyO') return;

      if (selectedCommentId !== 'reply' && selectedCommentId !== 'new')
        dispatch(selectComment('new'));

      if (e.code === 'KeyI') {
        const endMark = timeRange[0] === timeRange[1] ? duration : timeRange[1];
        console.log(currentTime);

        const range = [currentTime, endMark];

        setTimeRange(range);
        dispatch(setPlayerTime(range));
      }
      if (e.code === 'KeyO') {
        const startMark = timeRange[0] === timeRange[1] ? 0 : timeRange[0];
        const range = [startMark, currentTime];
        setTimeRange(range);
        dispatch(setPlayerTime(range));
      }
    };
    document.addEventListener('keydown', onKeyPress);
    return () => {
      document.removeEventListener('keydown', onKeyPress);
    };
  }, [
    timeRange,
    dispatch,
    duration,
    isCommentFieldFocused,
    currentTime,
    canvasMode,
    selectedCommentId
  ]);

  useEffect(() => {
    if (asset) {
      if (assetType !== 'video') return;
      setScrubsUrl(asset.metadata.scrub.url || '');
      setFrameWidth(asset.metadata.scrub.frames?.dimensions.width || 0);
      setFrameHeight(asset.metadata.scrub.frames?.dimensions.height || 0);
      setFramesCount(asset.metadata.scrub.frames?.count || 0);
    }
  }, [asset]);

  const setRange = (timeCode: any) => {
    const isRange = timeCode.toSeconds && timeCode.toSeconds >= 0;
    if (isRange) {
      setTimeRange([timeCode.fromSeconds || 0, timeCode.toSeconds || 0]);
      dispatch(
        setPlayerTime([timeCode.fromSeconds || 0, timeCode.toSeconds || 0])
      );
    } else {
      setTimeRange([timeCode.fromSeconds || 0, timeCode.fromSeconds || 0]);
      dispatch(setPlayerTime([timeCode.fromSeconds || 0, null]));
    }
  };

  useEffect(() => {
    if (!commentDetails?.timeCode) {
      if (selectedCommentId !== 'reply' && selectedCommentId !== 'new') {
        setTimeRange([playerTime[0] ?? 0]);
        dispatch(setPlayerTime([playerTime[0]]));
      }
      return;
    }
    setRange(commentDetails.timeCode);
  }, [selectedCommentId, commentDetails, canvasMode]);

  useEffect(() => {
    if (playerTime.length <= 0) return;
    setTimeRange([
      playerTime[0] as number,
      (playerTime[1] || playerTime[0]) as number
    ]);
  }, [playerTime]);

  const scrubViewImage = (e: any) => {
    const canvasEl = scrubRef.current;
    const lineEl = scrubLineRef.current;
    const timeEl = scrubTimeRef.current;
    const containerEl = scrubContainerRef.current;
    if (!canvasEl || !lineEl || !timeEl || !containerEl) return;
    const rect = lineEl.getBoundingClientRect();
    const stepWidth = rect.width / framesCount;
    const frameIndex = Math.floor((e.clientX - rect.left) / stepWidth);
    const img = new Image();
    canvasEl.frameIndex = frameIndex;
    img.src = scrubsUrl;
    img.onload = () => {
      const position = e.clientX - rect.left;
      const elWidth = rect.width;
      const scrubsContainerWidth = 160;
      let scrubsContainerHalfWidth = scrubsContainerWidth / 2;
      if (position < scrubsContainerHalfWidth)
        scrubsContainerHalfWidth = position;
      if (elWidth - position < 80)
        scrubsContainerHalfWidth = scrubsContainerWidth - (elWidth - position);
      containerEl.style.left = `${Math.floor(
        e.clientX - rect.left - scrubsContainerHalfWidth
      )}px`;
      canvasEl.style.border = '2px solid #FFFFFF';
      const percent = (e.clientX - rect.left) / rect.width;
      timeEl.innerText = formatDuration(duration * percent);
      if (!canvasEl || canvasEl.frameIndex !== frameIndex) return;
      const ctx = canvasEl.getContext('2d');
      ctx.drawImage(
        img,
        0,
        frameIndex * frameHeight,
        frameWidth,
        frameHeight,
        0,
        0,
        frameWidth,
        frameHeight
      );
    };
  };

  const showTimeAudio = (e: any) => {
    const lineEl = scrubLineRef.current;
    const timeEl = scrubTimeRef.current;
    const containerEl = scrubContainerRef.current;
    if (!lineEl || !timeEl || !containerEl) return;
    const rect = lineEl.getBoundingClientRect();
    const position = e.clientX - rect.left;
    const percent = position / rect.width;
    timeEl.innerText = formatDuration(duration * percent);
    const containerWidth = containerEl.clientWidth;
    let offsetLeft = position - containerWidth / 2;
    if (offsetLeft <= 0) {
      offsetLeft = 0;
    }
    if (position >= rect.width - containerWidth) {
      offsetLeft = rect.width - containerWidth;
    }
    containerEl.style.left = `${Math.floor(offsetLeft)}px`;
  };

  return (
    <>
      <LoadedBar buffered={buffered} duration={duration} />
      {scrubsVisible && assetType === 'video' && (
        <div className="scrubs_container" ref={scrubContainerRef}>
          <canvas ref={scrubRef} width={frameWidth} height={frameHeight} />
          <div className="time" ref={scrubTimeRef} />
        </div>
      )}
      {scrubsVisible && assetType === 'audio' && (
        <div className="scrubs_time_container" ref={scrubContainerRef}>
          <div className="time" ref={scrubTimeRef} />
        </div>
      )}
      <div
        className="video_control_bar_track"
        ref={scrubLineRef}
        onMouseEnter={() => {
          setScrubsVisible(true);
        }}
        onMouseLeave={() => {
          setScrubsVisible(false);
        }}
        onMouseMove={(e: any) => {
          if (assetType === 'video') scrubViewImage(e);
          if (assetType === 'audio') showTimeAudio(e);
        }}
        onFocus={() => null}
      >
        <MarksBar
          duration={duration}
          onSliderChange={onSliderChange}
          setTimeRange={setTimeRange}
          timeRange={timeRange}
        />
        <Slider
          value={(currentTime / duration) * 100}
          onChange={(value) => {
            const time = (duration / 100) * value;
            const isRange = timeRange[0] !== timeRange[1];
            if (isTimecodeChecked && isRange && canvasMode !== 'edit') {
              if (time > timeRange[1] || time < timeRange[0]) {
                onSliderChange(timeRange[0]);
              } else {
                onSliderChange(time);
              }
            } else {
              dispatch(setPlayerTime([time, null]));
              setTimeRange([time, time]);
              onSliderChange(time);
            }
          }}
          step={0.1}
          getTooltipPopupContainer={() =>
            document.querySelector('.video-js') as HTMLElement
          }
          marks={commentsList
            .filter((el) => el.timeCode)
            .reduce((a, v) => {
              return {
                ...a,
                [((v.timeCode?.fromSeconds || 0) / duration) * 100]: (
                  <Mark
                    user={v.user}
                    id={v.id || ''}
                    comment={v.text}
                    mentions={v.mentions}
                    groupMentions={v.groupMentions}
                    color={hashedColor(v.user?.id || '', 'mediaViewerComment')}
                    isPaused={isPaused}
                    isExpand={isExpand}
                    isRange={timeRange[0] !== timeRange[1]}
                  />
                )
              };
            }, {})}
        />
      </div>
    </>
  );
}

export default ControlBarSlider;
