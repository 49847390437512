import React, { useEffect, useContext, useRef, useState } from 'react';
import videojs, { VideoJsPlayer } from 'video.js';
import 'video.js/dist/video-js.css';
import { AssetVersionItemDto } from '@api/Api';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from '@hooks';
import {
  setPlayerTime,
  setToolsExpanded
} from '@redux/actions/mediaViewerAction';
import PlaybackPreparingLoader from '@pages/MediaViewer/AssetViewer/PlaybackPreparingLoader';
import { checkCanPlaySource } from '@helpers/checkCanPlaySource';
import FabricCanvas from '@pages/MediaViewer/AssetViewer/Canvas/Canvas';
import { FabricContext } from '@context/FabricContext';
import { nonNull } from '@helpers/non-null';
import ControlBar from '@pages/MediaViewer/AssetViewer/VideoCard/components/ControlBar/ControlBar';

const videoJsOptions = {
  autoplay: false,
  aspectRatio: '16:9',
  fluid: true
};

function VideoCard({
  onReady,
  onError,
  asset,
  isPreparing,
  isAssetReady
}: {
  onReady: (a: any) => any;
  onError: () => any;
  asset: AssetVersionItemDto;
  isPreparing: boolean;
  isAssetReady: boolean;
}) {
  const dispatch = useDispatch();
  const { selectedCommentId, playerTime } = useTypedSelector(
    ({ mediaViewer }) => mediaViewer
  );
  const videoRef = React.useRef<HTMLVideoElement>(null);
  const [player, setPlayer] = useState<VideoJsPlayer | null>(null);
  const videoContainerRef = React.useRef<any>(null);

  const { canvas } = useContext(FabricContext);

  useEffect(() => {
    if (!isAssetReady) return;
    if (!canvas) return;
    const resizeObserver = new ResizeObserver(() => {
      if (!videoContainerRef.current) return;

      const { width, height } =
        videoContainerRef.current.getBoundingClientRect();

      const scale = width / canvas.getWidth();
      const zoom = canvas.getZoom() * scale;
      canvas.setDimensions({
        width,
        height
      });

      canvas.setViewportTransform([zoom, 0, 0, zoom, 0, 0]);
    });
    resizeObserver.observe(videoContainerRef.current);

    return () => resizeObserver.disconnect();
  }, [isAssetReady, canvas]);

  const onReadyRef = useRef(onReady);
  onReadyRef.current = onReady;

  const onErrorRef = useRef(onError);
  onErrorRef.current = onError;

  useEffect(() => {
    const videoElement = nonNull(videoRef.current);
    const player = videojs(videoElement, videoJsOptions, function onReady() {
      if (onReadyRef.current) onReadyRef.current(this);
      // eslint-disable-next-line react/no-this-in-sfc
      this.on('play', () => {
        dispatch(setToolsExpanded(false));
      });
      // eslint-disable-next-line react/no-this-in-sfc
      this.on('error', () => {
        if (onErrorRef.current) onErrorRef.current();
      });
    });
    setPlayer(player);
    return () => {
      player.dispose();
    };
  }, [dispatch]);

  useEffect(() => {
    // TODO: this or similar code exists in many places (here, in AssetViewer.tsx and in ControlBar.tsx)
    // We need to do this once and then just pass "currentSource" and "playableSources" as props
    // TBD: not sure if this need to be in AssetViewer.tsx or here.
    // Probably here, because it is responsibility of video player to decide what and when it can play.
    // Then we can just utilize onReady callback to set "isPreparing" in AssetViewer.tsx.
    // In that case pass "isPreparing" as prop is also redundant.
    if (!player) return;
    player.pause();
    if (isPreparing) return;

    if (asset.metadata.type === 'video') {
      const readyProxies = Object.entries(asset.metadata.videoProxies)
        .map(([key, value]) => ({ key: parseInt(key, 10), value }))
        .filter((el) => el.value.status === 'ready')
        .sort((a, b) => b.key - a.key);
      const defaultProxy =
        readyProxies.find((el) => el.key <= 720) ??
        readyProxies.find((el) => el.key <= 1080);
      if (defaultProxy) {
        player.src({
          type: 'video/mp4',
          src: defaultProxy.value.url
        });
      } else if (checkCanPlaySource(asset)) {
        player.src({
          type: asset.mimeType,
          src: asset.sourceUrl
        });
      }
    } else if (asset.metadata.type === 'audio') {
      if (asset.metadata.audioProxy.url) {
        player.src({
          type: 'video/mp4',
          src: asset.metadata.audioProxy.url
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [player, asset.id, isPreparing]);

  useEffect(() => {
    if (!player) return;
    if (selectedCommentId === 'new' && !playerTime[1]) {
      dispatch(setPlayerTime([player.currentTime(), null]));
      player.pause();
    }
  }, [player, dispatch, selectedCommentId]);

  return (
    <>
      <div
        data-vjs-player
        className="video-js-container"
        ref={videoContainerRef}
      >
        {isPreparing && <PlaybackPreparingLoader isVideo={true} />}
        <video ref={videoRef} className="video-js" playsInline />
        {player && <ControlBar player={player} asset={asset} />}
      </div>
      {isAssetReady && <FabricCanvas />}
    </>
  );
}

export default VideoCard;
