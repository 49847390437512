import { AudioVideoMetadataDto } from '@api/Api';
import { useTypedSelector } from '@hooks';
import { useEffect, useState } from 'react';
import { VideoJsPlayer } from 'video.js';

let intervalRewind: ReturnType<typeof requestAnimationFrame>;
const keysPressed: { [key: string]: boolean } = {};

type useKeysProps = {
  duration: number;
  isPaused: boolean;
  player: VideoJsPlayer;
  isLooped: boolean;
  setIsPaused: (v: boolean) => void;
  onFastForward: (v: number) => void;
  setPlaybackSpeed: (v: number) => void;
};
export default function useKeys({
  onFastForward,
  setPlaybackSpeed,
  setIsPaused,
  duration,
  isLooped,
  isPaused,
  player
}: useKeysProps) {
  const [speed, setSpeed] = useState(0);
  const selectedVersion = useTypedSelector(
    ({ mediaViewer }) => mediaViewer.selectedVersion
  );
  const timeFormat = useTypedSelector(
    ({ mediaViewer }) => mediaViewer.timeFormat
  );
  const isCommentFieldFocused = useTypedSelector(
    ({ mediaViewer }) => mediaViewer.isCommentFieldFocused
  );

  const playPause = () => {
    player.playbackRate(1);
    setPlaybackSpeed(1);

    if (isPaused) {
      if (speed < 0) {
        setSpeed(0);
        cancelAnimationFrame(intervalRewind);
        return;
      }
      player.play();
      setIsPaused(false);
      setSpeed(1);
    } else {
      player.pause();
      setIsPaused(true);
      setSpeed(0);
    }
  };

  const slow = () => {
    cancelAnimationFrame(intervalRewind);
    setSpeed(0);
    setPlaybackSpeed(0.5);
    player.playbackRate(0.5);
    player.play();
    setIsPaused(false);
  };

  const KeyL = (multiplier: number) => {
    cancelAnimationFrame(intervalRewind);
    setPlaybackSpeed(multiplier);
    player.playbackRate(multiplier);
    player.play();
    setIsPaused(false);
  };

  const KeyJ = (multiplier: number) => {
    setPlaybackSpeed(multiplier);

    player.pause();
    setIsPaused(true);

    let lastDate = Date.now();

    cancelAnimationFrame(intervalRewind);
    const play = () => {
      const elapsedTime = Date.now() - lastDate;

      const time = (elapsedTime / 1000) * Math.abs(multiplier);

      if (player.currentTime() === 0) {
        if (isLooped) {
          player.currentTime(duration);
        } else {
          cancelAnimationFrame(intervalRewind);
        }
      } else {
        if (!player.seeking()) onFastForward(-time);
      }
      lastDate = Date.now();

      intervalRewind = requestAnimationFrame(play);
    };
    intervalRewind = requestAnimationFrame(play);
  };

  useEffect(() => {
    if (isCommentFieldFocused) return;

    const onKeyPress = (e: any) => {
      const modalElement = document.querySelector('.ant-modal-root');
      const fieldElement = document.querySelector(
        '.media_viewer_filter-search-input'
      );

      if (modalElement || fieldElement) return;
      const metadata = selectedVersion?.metadata as AudioVideoMetadataDto;
      const sourceFrameRate = metadata.info.avInfo?.video?.frameRate ?? 30;
      const proxyFrameRate = Math.min(sourceFrameRate, 30);
      const frameRate = proxyFrameRate;
      const value = timeFormat === 'frames' ? 1 / frameRate : 5;

      if (e.code === 'KeyJ' || e.code === 'KeyL') {
        keysPressed[e.code] = true;
        if (keysPressed.KeyJ && keysPressed.KeyL) {
          slow();
          return;
        }
      }

      if (e.code === 'ArrowLeft') {
        onFastForward(-value);
      }
      if (e.code === 'ArrowRight') {
        onFastForward(value);
      }

      if (e.code === 'KeyK' || e.code === 'Space') {
        playPause();
      }

      if (e.code === 'KeyJ') {
        if (speed > 1) {
          setSpeed(speed - 1);
          KeyL(speed - 1);
          return;
        }
        if (speed === 1) {
          setSpeed(-1);
          KeyJ(-1);
          return;
        }
        let _speed = speed - 1 >= -8 ? speed - 1 : speed;
        if (_speed === 0) _speed = -1;
        setSpeed(_speed);
        KeyJ(_speed);
      }
      if (e.code === 'KeyL') {
        if (speed < -1) {
          setSpeed(speed + 1);
          KeyJ(speed + 1);
          return;
        }
        if (speed === -1) {
          setSpeed(1);
          KeyL(1);
          return;
        }
        let _speed = speed + 1 > 8 ? speed : speed + 1;
        if (_speed === 0) _speed = 1;
        setSpeed(_speed);

        KeyL(_speed);
      }
    };

    document.addEventListener('keydown', onKeyPress);

    const keyup = (e: any) => {
      delete keysPressed[e.code];
    };
    document.addEventListener('keyup', keyup);
    return () => {
      document.removeEventListener('keydown', onKeyPress);
      document.removeEventListener('keyup', keyup);
    };
  }, [
    speed,
    duration,
    isCommentFieldFocused,
    isLooped,
    isPaused,
    onFastForward,
    player,
    selectedVersion?.metadata,
    setIsPaused,
    setPlaybackSpeed,
    timeFormat
  ]);

  return { playInReverse: speed < 0, playPause };
}
