import { formatDuration } from '@helpers/formatDuration';
import { useSelectedMVComment, useTypedSelector } from '@hooks';
import { setPlayerTime, setTimeFormat } from '@redux/actions/mediaViewerAction';
import { ReactComponent as DownArrowSvg } from '@assets/icons/arrow-down.svg';
import { Menu, Popover, Tooltip } from 'antd';
import React, { memo, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { AudioVideoMetadataDto } from '@api/Api';

type ControlBarTimerProps = {
  currentTime: number;
  duration: number;
  isSource: boolean;
};

export default memo(function ControlBarTimer({
  currentTime,
  duration,
  isSource
}: ControlBarTimerProps) {
  const dispatch = useDispatch();
  const [timeFormatVisible, setTimeFormatVisible] = useState<boolean>(false);

  const selectedVersion = useTypedSelector(
    ({ mediaViewer }) => mediaViewer.selectedVersion
  );
  const playerTime = useTypedSelector(
    ({ mediaViewer }) => mediaViewer.playerTime
  );
  const isTimecodeChecked = useTypedSelector(
    ({ mediaViewer }) => mediaViewer.isTimecodeChecked
  );

  const canvasMode = useTypedSelector(
    ({ mediaViewer }) => mediaViewer.canvasMode
  );
  const timeFormat = useTypedSelector(
    ({ mediaViewer }) => mediaViewer.timeFormat
  );
  const selectedCommentId = useTypedSelector(
    ({ mediaViewer }) => mediaViewer.selectedCommentId
  );

  const commentDetails = useSelectedMVComment();
  const renderAssetTime = useMemo(() => {
    const metadata = selectedVersion?.metadata as AudioVideoMetadataDto;
    const sourceFrameRate = metadata.info.avInfo?.video?.frameRate ?? 30;
    const proxyFrameRate = Math.min(sourceFrameRate, 30);
    const frameRate = isSource ? sourceFrameRate : proxyFrameRate;
    const currentFrame = Math.floor(currentTime * frameRate);
    return (
      <>
        <div className="current">
          {timeFormat === 'standard'
            ? formatDuration(currentTime)
            : currentFrame}
        </div>
        <div className="divider">/</div>
        <div className="total">
          {timeFormat === 'standard'
            ? formatDuration(duration)
            : Math.floor(frameRate * duration)}
        </div>
      </>
    );
  }, [isSource, currentTime, duration, timeFormat, selectedVersion?.metadata]);

  return (
    <div className="video_control_bar_container__time">
      {(canvasMode === 'edit' ||
        selectedCommentId === 'new' ||
        selectedCommentId === 'reply') &&
      playerTime[1] &&
      playerTime[1] >= 0 &&
      isTimecodeChecked ? (
        <div
          className="clear_playback_box"
          onClick={() => {
            if (
              (canvasMode === 'edit' ||
                selectedCommentId === 'new' ||
                selectedCommentId === 'reply') &&
              playerTime[1] &&
              playerTime[1] >= 0
            ) {
              dispatch(setPlayerTime([playerTime[0], null]));
            }
          }}
        >
          <span className="copy">Clear playback range</span>
          <span className="action">Esc</span>
        </div>
      ) : (
        <Popover
          placement="topRight"
          trigger={['click']}
          getPopupContainer={() =>
            document.querySelector('.video-js') as HTMLElement
          }
          onOpenChange={(v) => setTimeFormatVisible(v)}
          open={timeFormatVisible}
          overlayStyle={{ width: 140 }}
          overlayClassName="control_bar_select_overlay"
          content={
            <Menu
              prefixCls="media_viewer_menu-menu"
              selectedKeys={[timeFormat]}
              onClick={(e) => {
                setTimeFormatVisible(false);
                dispatch(setTimeFormat(e.key as any));
              }}
              items={[
                { key: 'standard', label: 'Standard' },
                { key: 'frames', label: 'Frames' }
              ]}
            />
          }
        >
          <Tooltip
            title={
              timeFormat === 'standard'
                ? 'Switch to frame'
                : 'Switch to standard'
            }
            placement="bottom"
            overlayClassName="toggle_favorite_overlay"
          >
            <div style={{ display: 'flex' }}>
              {renderAssetTime} <DownArrowSvg />
            </div>
          </Tooltip>
        </Popover>
      )}
    </div>
  );
});
